<template lang="pug">
  .google-recaptcha-v2(ref="recaptchaElement")
</template>
<script>
const SCRIPT_URL = 'https://www.google.com/recaptcha/api.js'
export default {
  name: 'GoogleRecaptchaV2',
  props: {
    siteKey: { type: String, default: null, required: true },
    value: { type: String, default: null },
    theme: { type: String, default: 'light' },
  },
  data() {
    return {
      widgetId: null,
    }
  },
  computed: {
    currentValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  async mounted() {
    await this.loadScript()
    this.init()
  },
  methods: {
    reset() {
      console.log('reset', this.widgetId)
      if (this.widgetId !== null) {
        window.grecaptcha.reset(this.widgetId)
      }
    },
    async loadScript() {
      if (window.grecaptcha) {
        return
      }
      return new Promise(resolve => {
        const el = document.createElement('script')
        el.type = 'text/javascript'
        el.src = SCRIPT_URL + '?onload=vueGoogleRecaptchaV2Onload'
        window.vueGoogleRecaptchaV2Onload = () => {
          resolve()
        }
        // el.onload = resolve;
        el.async = true
        document.body.appendChild(el)
      })
    },
    init() {
      this.widgetId = window.grecaptcha.render(this.$refs.recaptchaElement, {
        theme: this.theme,
        sitekey: this.siteKey,
        callback: this.handleVerified,
        'expired-callback': this.handleExpired,
        'error-callback': this.handleError,
      })
    },
    handleVerified(token) {
      this.currentValue = token
    },
    handleExpired(response) {
      this.$emit('expired', response)
      this.currentValue = null
    },
    handleError(response) {
      this.$emit('error', response)
      this.currentValue = null
    },
  },
}
</script>
<style lang="sass">
  .google-recaptcha-v2
    width: 304px
</style>
