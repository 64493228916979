<template lang="pug">
  .login-container
    el-form(ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left")
      h3.title {{ $t('login.title') }}
      // lang-select.set-language
      el-form-item(prop="email" :class="{ 'is-disabled': confirmCodeSent || tempPassword }")
        span.svg-container
          svg-icon(icon-class="user")
        el-input(v-model="loginForm.email" name="email" type="text" auto-complete="on" :placeholder="$t('login.email')" :disabled="confirmCodeSent || tempPassword")
      el-form-item(prop="password" :class="{ 'is-disabled': confirmCodeSent || tempPassword }")
        span.svg-container
          svg-icon(icon-class="password")
        el-input(
          :type="pwdType"
          v-model="loginForm.password"
          name="password"
          auto-complete="on"
          placeholder="Пароль"
          @keyup.enter.native="handleLogin"
          :disabled="confirmCodeSent || tempPassword"
          )
        span.show-pwd(@click="showPwd")
          svg-icon(icon-class="eye")
      .tips(v-if="tempPassword") Необходимо установить новый пароль
      .tips(v-else-if="authTimeout") {{ authTimeoutError }}
      .tips(v-else-if="confirmCodeSendTimeout")
        template(v-if="confirmCodeSendTimeoutTick > 0") Повторная отправка сообщения возможна через {{ confirmCodeSendTimeoutTick }} с
        template(v-else) Повторная отправка сообщения уже возможна, обновите страницу!
      .tips(v-else-if="confirmCodeSent") На почту {{ loginForm.email }} отправлено письмо с кодом подтверждения
      el-form-item(prop="secret" v-if="confirmCodeSent && !tempPassword")
        span.svg-container
          svg-icon(icon-class="lock")
        el-input(
          type="text"
          v-model="loginForm.secret"
          name="secret"
          placeholder="Код подтверждения"
          @keyup.enter.native="handleLogin"
          )
      template(v-if="tempPassword")
        el-form-item(prop="newPassword")
          span.svg-container
            svg-icon(icon-class="lock")
          el-input(
            show-password
            ref="newPassword"
            type="text"
            v-model="loginForm.newPassword"
            name="newPassword"
            placeholder="Новый пароль"
            @keyup.enter.native="handleLogin"
          )
        el-form-item(prop="repeatNewPassword")
          span.svg-container
            svg-icon(icon-class="lock")
          el-input(
            show-password
            type="text"
            v-model="loginForm.repeatNewPassword"
            name="repeatNewPassword"
            placeholder="Повторите новый пароль"
            @keyup.enter.native="handleLogin"
          )
        .tips(v-if="loginForm.newPassword !== loginForm.repeatNewPassword") Пароли не совпадают
        .tips
          p Пароль должен:
          ul
            li(v-if="securitySettings.passwordRequireUpperLetters") содержать не меньше 1 буквы в верхнем регистре
            li(v-if="securitySettings.passwordRequireLowerLetters") содержать не меньше 1 буквы в нижнем регистре
            li(v-if="securitySettings.passwordRequireNumbers") содержать не меньше 1 цифры
            // li(v-if="securitySettings.passwordRequireUpperLetter") содержать не меньше 1 специального символа
            li(v-if="securitySettings.passwordMinLength") иметь длину не менее {{ securitySettings.passwordMinLength }} символов
            li(v-if="securitySettings.passwordMaxLength") иметь длину не более {{ securitySettings.passwordMaxLength }} символов
      google-recaptcha(
        ref="recaptcha"
        v-if="!confirmCodeSent && !tempPassword"
        v-model="loginForm.googleRecaptchaToken"
        theme="dark"
        :site-key="recaptchaSiteKey"
      ).login-page__recaptcha
      el-button(
        type="info"
        :loading="loading"
        style="width:100%;"
        @click.native.prevent="generateNewPassword"
        v-if="tempPassword"
      ) Сгенерировать и вставить пароль
      p
      el-button(
        :loading="loading"
        type="primary"
        style="width:100%;"
        :disabled="loading || !loginForm.newPassword || loginForm.newPassword !== loginForm.repeatNewPassword"
        @click.native.prevent="handleUpdatePassword"
        v-if="tempPassword"
      ) Сохранить
      el-button(:loading="loading" type="primary" style="width:100%;" @click.native.prevent="handleLogin" v-else) Войти
      div.tips(v-if="defaultUser && defaultUser.email && defaultUser.password")
        span(style="margin-right:20px;") Email: {{ defaultUser.email }}
        span Password: {{ defaultUser.password }}
</template>

<script>
import LangSelect from '@/components/LangSelect'
import GoogleRecaptcha from '@/components/GoogleRecaptcha'
import { validEmail } from '@/utils/validate'

import { twoFactorLogin, twoFactorSecret, login, updatePassword } from '@/api/auth'
import { notifyRequest } from '@/utils/api'

const defaultUser = window.__data.defaultUser
const recaptchaSiteKey = window.__data.recaptchaSiteKey

export default {
  name: 'Login',
  components: { LangSelect, GoogleRecaptcha },
  mounted() {
    // test message
    // this.$notify({ title: 'Успешная аутентификация!', message: 'Сейчас откроется администраторская панель', duration: 50000, type: 'success'}, );
  },
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!validEmail(value)) {
        callback(new Error('Введите корректный адрес E-mail'))
      } else {
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      if (!value || value.length < 8) {
        callback(new Error('Пароль не может иметь длину меньше 8 символов'))
      } else {
        callback()
      }
    }
    const validateSecret = (rule, value, callback) => {
      if (!value || value.length !== 16) {
        callback(new Error('Длина кода подтверждения, отправленного на Ваш email составляет 16 символов'))
      } else {
        callback()
      }
    }
    return {
      defaultUser: defaultUser,
      recaptchaSiteKey: recaptchaSiteKey,
      loginForm: { ...defaultUser, secret: '', googleRecaptchaToken: '' } || {
        email: '',
        password: '',
        secret: '',
        googleRecaptchaToken: '',
        newPassword: '',
        repeatNewPassword: ''
      },
      loginRules: {
        email: [{ required: true, trigger: 'blur', validator: validateEmail }],
        password: [{ required: true, trigger: 'blur', validator: validatePass }],
        // secret: [{ required: true, trigger: 'blur', validator: validateSecret }]
        googleRecaptchaToken: [{ required: true, trigger: 'blur' }]
      },
      loading: false,
      pwdType: 'password',
      redirect: undefined,
      confirmCodeSent: false,
      confirmCodeSendTimeout: false,
      confirmCodeSendTimeoutTick: 0,

      authFailed: false,
      authFailedError: null,

      authTimeout: false,
      authTimeoutError: '',

      intermediateToken: null,

      tempPassword: false,
      tempToken: null,

      securitySettings: this.$store.state.settings.security,
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true,
    },
  },
  methods: {
    resetRecaptcha() {
      this.$refs.recaptcha.reset()
    },
    showPwd() {
      if (this.pwdType === 'password') {
        this.pwdType = ''
      } else {
        this.pwdType = 'password'
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.confirmCodeSendTimeout = false
          this.authTimeout = false
          if (this.confirmCodeSendTimer) {
            clearInterval(this.confirmCodeSendTimer)
          }
          if (this.$store.state.settings.emailAuthentication) {
            if (this.confirmCodeSent) {
              this.handleTwoFactorSecret()
            } else {
              this.handleTwoFactorLogin()
            }
          } else {
            this.handleSimpleLogin()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async generateNewPassword() {
      this.loading = true
      this.loginForm.newPassword = 'Генерация пароля'
      this.loginForm.repeatNewPassword = 'Генерация пароля'
      const response = await notifyRequest({
        url: 'generate-password',
        method: 'get'
      }, 'Пароль успешно сгенерирован и вставлен')
      const password = response.password
      this.loginForm.newPassword = password
      this.loginForm.repeatNewPassword = password
      this.$refs.newPassword.passwordVisible = true
      this.loading = false
    },
    async handleUpdatePassword() {
      const response = await updatePassword(this.tempToken, this.loginForm.newPassword)
      const data = response.data
      const status = response.status
      if (data.success) {
        await this.$store.dispatch('user/setToken', data.token)
        this.$notify({ title: 'Успешная аутентификация!', message: 'Сейчас откроется администраторская панель', duration: 5000, type: 'success' },)
        window.location.href = this.redirect || '/'
        return
      }
      this.$notify({ title: 'Ошибка аутентификации!', message: data.error, duration: 5000, type: 'danger' },)
    },
    async handleSimpleLogin() {
      const response = await login(this.loginForm.email, this.loginForm.password, this.loginForm.googleRecaptchaToken)
      const data = response.data
      const status = response.status
      if (data.success) {
        await this.$store.dispatch('user/setToken', data.token)
        this.$notify({ title: 'Успешная аутентификация!', message: 'Сейчас откроется администраторская панель', duration: 5000, type: 'success' },)
        window.location.href = this.redirect || '/'
        return
      }
      this.resetRecaptcha()
      this.loading = false
      if (data.data && data.data.error_code === 'temp_password') {
        this.tempPassword = true
        this.tempToken = data.data.tempToken
        this.$notify({ title: 'Необходимо установить пароль', message: data.error, duration: 5000, type: 'danger' },)
        return
      }
      if (data.data && data.data.error_code === 'auth_timeout') {
        this.authTimeout = true
        this.authTimeoutError = data.error
        return
      }
      this.$notify({ title: 'Ошибка аутентификации!', message: data.error, duration: 5000, type: 'danger' },)
    },
    async handleTwoFactorLogin() {
      this.loading = true
      const response = await twoFactorLogin(this.loginForm.email, this.loginForm.password, this.loginForm.googleRecaptchaToken)
      const data = response.data
      const status = response.status
      if (data.success) {
        this.intermediateToken = data.data.intermediateToken
        this.confirmCodeSent = true
        this.loading = false
        this.$notify({ title: 'Успешно!', message: 'На вашу почту отправлено письмо с кодом подтверждения', duration: 5000, type: 'success' },)
        return
      }
      this.resetRecaptcha()
      this.loading = false
      if (data.data && data.data.error_code === 'code_send_timeout') {
        this.intermediateToken = data.data.intermediateToken
        this.confirmCodeSent = true
        this.confirmCodeSendTimeout = true
        this.confirmCodeSendTimeoutTick = data.data.timeout
        this.confirmCodeSendTimer = setInterval(() => {
          if (this.confirmCodeSendTimeoutTick >= 0) {
            this.confirmCodeSendTimeoutTick--
          } else {
            clearInterval(this.confirmCodeSendTimer)
          }
        }, 1000)
        return
      }
      if (data.data && data.data.error_code === 'auth_failed') {
        this.authTimeout = true
        this.authTimeoutError = data.error
        return
      }
      if (data.data && data.data.error_code === 'auth_timeout') {
        this.authTimeout = true
        this.authTimeoutError = data.error
        return
      }
      this.$notify({ title: 'Ошибка аутентификации!', message: data.error, duration: 5000, type: 'danger' },)
    },
    async handleTwoFactorSecret() {
      this.loading = true
      const response = await twoFactorSecret(this.intermediateToken, this.loginForm.secret)
      const data = response.data
      const status = response.status
      if (data.success) {
        this.loading = false
        await this.$store.dispatch('user/setToken', data.token)
        this.$notify({ title: 'Успешная аутентификация!', message: 'Сейчас откроется администраторская панель', duration: 5000, type: 'success' },)
        window.location.href = this.redirect || '/'
        return
      }
      this.loading = false
      if (data.data && data.data.error_code === 'temp_password') {
        this.tempPassword = true
        this.tempToken = data.data.tempToken
        this.$notify({ title: 'Необходимо установить пароль', message: data.error, duration: 5000, type: 'danger' },)
        return
      }
      this.$notify({ title: 'Ошибка аутентификации!', message: data.error, duration: 5000, type: 'danger' },)
    },
  },
}
</script>

<style rel="stylesheet/scss" lang="scss">
  @import './index';
</style>
